<template>
  <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
            <div class="workers-info-title-za">
                <div class="title">{{$t('message.complaints')}}</div>
            </div>

            <div class="m-3">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="refa__chart">
                            <el-card class="box-card bg-light-warning" :class="mode ? '' : 'text-warning__night'">
                                <i
                                    class="my-icon el-icon-tickets text-warning"
                                ></i>
                                <div class="mobile__izn">
                                  <div class="text-center">
                                      <span class="text-warning">
                                          {{$t('message.total_qty')}}
                                      </span>
                                  </div>
                                  <div class="text-center mt-3">
                                      <b class="text-warning">{{profile_complaints.length}}</b>
                                  </div>
                                </div>
                            </el-card>
                        </div>
                    </el-col>

                    <el-col :span="24">
                        <div class="scrol-table crmhome__scroll">
                            <table
                                :class="mode ? 'table__myday' : 'table__mynight'"
                                class="
                                    table-my-code table-bordered
                                    table__layout
                                    my-table-card my-clendar
                                "
                            >
                                <thead>
                                    <tr>
                                        <th class="w50p" scope="col">{{$t('message.n')}}</th>
                                        <th scope="col">{{$t('message.request_time')}}</th>
                                        <th scope="col">{{$t('message.manager')}}</th>
                                        <th scope="col">{{$t("message.subject_complaint")}}</th>
                                        <th scope="col">{{$t('message.comment')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr  v-for="(complaint, index) in profile_complaints"
                                    :key="'complaint-' + index">
                                        <td>{{ complaint.id }}</td>
                                        <td> {{ complaint.created_at }}</td>
                                        <td>{{
                                              complaint.manager
                                                ? complaint.manager.name + " " + complaint.manager.last_name
                                                : ""
                                            }}
                                          </td>
                                        <td> {{ complaint.header }}</td>
                                        <td>{{ complaint.title }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name:'profile-complaint',
  props: {
      staff_id: {
        type: Number,
      },
    },

  data() {
    return {
      profile_complaints:{},
    };
  },
  mounted() {
    this.table();
  },
  computed: {
    ...mapGetters({
      list: "complaint/list",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "complaint/complaint",
    }),

     table(){
      const query = { staff_id: this.staff_id};
      this.updateList(query).then((res) => {
        this.profile_complaints = res.data.result.data.complaints;
      });
    },
  }
}
</script>
