<template>
  <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
            <div class="workers-info-title-za">
                <div class="title" :class="mode ? '' : 'content__titlenight'">{{$t("message.izn")}}</div>
            </div>

            <div class="m-3">
                <el-row :gutter="20" >
                   <el-col :xs="24" :sm="6">
                        <div class="refa__chart">
                            <el-card class="box-card bg-light-primary" :class="mode ? '' : 'text-primary__night'">
                                <i
                                    class="my-icon el-icon-tickets text-primary"
                                ></i>
                                <div class="mobile__izn">
                                  <div class="text-center">
                                      <span class="text-primary">
                                        {{$t("message.total_qty")}}
                                      </span>
                                  </div>
                                  <div class="text-center mt-3">
                                      <b class="text-primary">{{profile_take_assents.length}}</b>
                                  </div>
                                </div>
                            </el-card>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="6">
                        <div class="refa__item">
                            <el-card class="box-card bg-light-success" :class="mode ? '' : 'text-success__night'">
                                <i
                                    class="
                                        my-icon
                                        el-icon-document-checked
                                        text-success
                                    "
                                ></i>
                                <div class="mobile__izn">
                                  <div class="text-center">
                                    <span class="text-success">
                                        {{$t("message.allowed")}}
                                    </span>
                                  </div>
                                  <div class="text-center mt-3">
                                      <b class="text-success">{{accept}}</b>
                                  </div>
                                </div>
                            </el-card>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="6">
                        <div class="refa__item">
                            <el-card class="box-card bg-light-warning" :class="mode ? '' : 'text-warning__night'">
                                <i
                                    class="
                                        my-icon
                                        el-icon-document-add
                                        text-warning
                                    "
                                ></i>
                                <div class="mobile__izn">
                                  <div class="text-center">
                                    <span class="text-warning">
                                        {{$t("message.waiting")}}
                                    </span>
                                  </div>
                                  <div class="text-center mt-3">
                                      <b class="text-warning"> {{waiti}} </b>
                                  </div>
                                </div>
                            </el-card>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="6">
                        <div class="refa__item">
                            <el-card class="box-card bg-light-danger" :class="mode ? '' : 'text-danger__night'">
                                <i
                                    class="
                                        my-icon
                                        el-icon-document-delete
                                        text-danger
                                    "
                                ></i>
                                <div class="mobile__izn">
                                  <div class="text-center">
                                    <span class="text-danger">
                                        {{$t("message.rejected")}}
                                    </span>
                                  </div>
                                  <div class="text-center mt-3">
                                      <b class="text-danger"> {{deny}} </b>
                                  </div>
                                </div>
                            </el-card>
                        </div>
                    </el-col>
                    
                    <el-col :xs="24" :sm="24">
                        <div class="scrol-table crmhome__scroll">
                            <table
                                :class="mode ? 'table__myday' : 'table__mynight'"
                                class="
                                    table-my-code table-bordered
                                    table__layout
                                    my-table-card my-clendar
                                "
                            >
                                <thead>
                                    <tr>
                                        <th class="w50p" scope="col">{{$t("message.n")}}</th>
                                        <th scope="col">{{$t("message.request_time")}}</th>
                                        <th scope="col">{{$t("message.date_of_departure")}}</th>
                                        <th scope="col">{{$t("message.arrival_date")}}</th>
                                        <th scope="col">{{$t("message.reason")}}</th>
                                        <th scope="col">{{$t("message.manager")}}</th>
                                        <th class="w250" scope="col">
                                            {{$t("message.comment")}}
                                        </th>
                                        <th scope="col">{{$t("message.status")}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr  v-for="(take_assent, index) in profile_take_assents"
                                    :key="'take_assent-' + index">
                                        <td>{{take_assent.id}}</td>
                                        <td>{{ take_assent.created_at }}</td>
                                        <td>{{ take_assent.left_date }} {{ take_assent.left_time }}</td>
                                        <td>{{ take_assent.coming_date }} {{ take_assent.coming_time }}</td>
                                        <td>{{ take_assent.reason ? take_assent.reason.name : "" }}</td>
                                        <td>
                                              {{
                                                take_assent.manager
                                                  ? take_assent.manager.name + " " + take_assent.manager.last_name
                                                  : ""
                                              }}
                                        </td>
                                        <td>{{ take_assent.comment }}</td>
                                        <td class="yes" v-if="take_assent.status == 'accept'">
                                        {{$t("message.received")}}
                                        </td>
                                         <td class="off"  v-if="take_assent.status == 'deny'">
                                             {{$t("message.denied")}}
                                        </td>
                                         <td class="yes waiti"  v-if="take_assent.status == 'waiting'">
                                           {{$t("message.waiting")}}
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name:'profile-take-assent',
  props: {
      staff_id: {
        type: Number,
      },
    },
  data() {
    return {
      profile_take_assents:{},
      waiti:0,
      deny:0,
      accept:0,
    };
  },
  mounted() {
    this.table();
  },
  computed: {
    ...mapGetters({
      list: "takeAssent/list",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "takeAssent/takeAssent",
    }),
    table() {
      const query = { staff_id: this.staff_id};
      this.updateList(query).then((res) => {
        this.profile_take_assents = res.data.result.data.take_assents;
          res.data.result.data.take_assents.forEach(element => {
            if (element.status == 'accept') {
              this.accept +=1;
            }
            else if(element.status == 'deny'){
              this.deny +=1;
            }
            else if(element.status == 'waiting'){
              this.waiti +=1;
            }
          });
      });
    },
  }
}
</script>
<style lang="scss" >
 .waiti{
  background-color: #E6A23C !important;
 }
 .cardtable__night{
  .title{
    color: #fff !important;
  }
 }
</style>
