<template>
   <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
            <div class="workers-info-title-za">
                <div class="title">{{$t("message.avance")}}  </div>
            </div>

            <div class="m-3">
                <el-row :gutter="20" >
                    <el-col :xs="24" :sm="6">
                        <div class="refa__chart">
                            <el-card class="box-card bg-light-primary" :class="mode ? '' : 'text-primary__night'">
                                <i
                                    class="my-icon el-icon-tickets text-primary"
                                ></i>
                                <div class="mobile__izn">
                                  <div class="text-center">
                                      <span class="text-primary">
                                            {{$t("message.total_qty")}}
                                      </span>
                                  </div>
                                  <div class="text-center mt-3">
                                      <b class="text-primary">{{advance_salaries.length}}</b>
                                  </div>
                                </div>
                            </el-card>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="6">
                        <div class="refa__item"> 
                            <el-card class="box-card bg-light-success" :class="mode ? '' : 'text-success__night'">
                                <i
                                    class="
                                        my-icon
                                        el-icon-document-checked
                                        text-success
                                    "
                                ></i>
                                <div class="mobile__izn">
                                  <div class="text-center">
                                      <span class="text-success">
                                            {{$t("message.allowed")}}
                                      </span>
                                  </div>
                                  <div class="text-center mt-3">
                                      <b class="text-success">{{accept}}</b>
                                  </div>
                                </div>
                            </el-card>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="6">
                        <div class="refa__item">
                            <el-card class="box-card bg-light-warning" :class="mode ? '' : 'text-warning__night'">
                                <i
                                    class="
                                        my-icon
                                        el-icon-document-add
                                        text-warning
                                    "
                                ></i>
                                <div class="mobile__izn">
                                  <div class="text-center">
                                      <span class="text-warning">
                                          {{$t("message.waiting")}}
                                      </span>
                                  </div>
                                  <div class="text-center mt-3">
                                      <b class="text-warning"> {{waiti}} </b>
                                  </div>
                                </div>
                            </el-card>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="6">
                        <div class="refa__item"> 
                            <el-card class="box-card bg-light-danger" :class="mode ? '' : 'text-danger__night'">
                                <i
                                    class="
                                        my-icon
                                        el-icon-document-delete
                                        text-danger
                                    "
                                ></i>
                                <div class="mobile__izn">
                                  <div class="text-center">
                                    <span class="text-danger">
                                      {{$t("message.rejected")}}
                                    </span>
                                  </div>
                                  <div class="text-center mt-3">
                                    <b class="text-danger"> {{deny}} </b>
                                  </div>
                                </div>
                            </el-card>
                        </div>
                    </el-col>
                    
                    <el-col :span="24">
                        <div class="scrol-table crmhome__scroll">
                            <table
                                :class="mode ? 'table__myday' : 'table__mynight'"
                                class="
                                    table-my-code table-bordered
                                    table__layout
                                    my-table-card my-clendar
                                "
                            >
                                <thead>
                                    <tr>
                                        <th class="w50p" scope="col">{{$t("message.n")}}</th>
                                        <th scope="col">{{$t("message.request_time")}}</th>
                                        <th scope="col">{{$t("message.date_time")}}</th>
                                        <th scope="col">{{$t("message.amount")}}</th>
                                        <th scope="col">{{$t("message.manager")}}</th>
                                        <th class="w250" scope="col">
                                            {{$t("message.comment")}}
                                        </th>
                                        <th scope="col">{{$t("message.status")}}</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr 
                                    v-for="(salary, index) in advance_salaries"
                                    :key="'salary-' + index">
                                        <td>{{salary.id}}</td>
                                        <td> {{ salary.created_at }}</td>
                                        <td>{{ salary.created_at }}</td>
                                        <td>{{ salary.amount }}</td>

                                        <td>{{
                                              salary.manager
                                                ? salary.manager.name + " " + salary.manager.last_name
                                                : ""
                                            }}
                                          </td>
                                        <td>
                                          {{ salary.comment }}
                                        </td>
                                        <td class="yes" v-if="salary.status == 'accept'">
                                         {{$t("message.received")}}
                                        </td>
                                         <td class="off"  v-if="salary.status == 'deny'">
                                          {{$t("message.denied")}}
                                        </td>
                                         <td class="yes waiti"  v-if="salary.status == 'waiting'">
                                          {{$t("message.waiting")}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name:'advance-salary',
  props: {
      staff_id: {
        type: Number,
      },
    },
  data() {
    return {
      advance_salaries:{},
      waiti:0,
      deny:0,
      accept:0,
    };
  },
  mounted() {
    this.table();
  },
   computed: {
    ...mapGetters({
      list: "advanceSalary/list",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "advanceSalary/advanceSalary",
    }),
    table(){
      const query = { staff_id: this.staff_id};
      this.updateList(query).then((res) => {
        this.advance_salaries = res.data.result.data.advance_salaries;
          res.data.result.data.advance_salaries.forEach(element => {
            if (element.status == 'accept') {
              this.accept +=1;
            }
            else if(element.status == 'deny'){
              this.deny +=1;
            }
            else if(element.status == 'waiting'){
              this.waiti +=1;
            }
          });
      });
    },

   
  },
};
</script>
<style lang="scss" >
 .waiti{
  background-color: #E6A23C !important;
 }
</style>
